<template>
  <v-container
    fluid
    tag="section"
  >
    <h1>Gemeldete Turnerinnen für {{ teamname }}:</h1>
    <v-card flat>
      <v-data-table
        :items="teamathletes"
        :headers="[
          { text: 'Nachname', value: 'familyName' },
          { text: 'Vorname', value: 'givenName' },
          { text: 'DTB-ID', value: 'dtbid', sortable: false },
          { text: 'Startmarke für', value: 'turnportal.club' },
          { text: 'Gültig ab', value: 'turnportal.validFrom' },
          { text: 'Gültig bis', value: 'turnportal.validUntil' },
          { text: 'Gesperrt', value: 'turnportal.banned' },
          { text: 'Letzte Überprüfung', value: 'turnportal.lastCheck' },
          { text: 'Geräte in DTL 2022*', value: 'dtlgeraete', align: 'center', sortable: false },
          { text: 'Bodenmusik**', value: 'music', sortable: false }
        ]"
        sort-by="familyName"
        :items-per-page="-1"
      >
        <template #item.turnportal.validFrom="{item}">
          {{ item.turnportal.validFrom | date }}
        </template>
        <template #item.turnportal.validUntil="{item}">
          {{ item.turnportal.validUntil | date }}
        </template>
        <template #item.turnportal.banned="{item}">
          {{ item.turnportal.banned ? 'JA!' : 'nein' }}
        </template>
        <template #item.turnportal.lastCheck="{item}">
          {{ item.turnportal.lastCheck | dateformat }}
        </template>
        <template #item.dtlgeraete="{item}">
          <div
            :style="{ background: item.dtlgeraete === null ? '#fc9895' : (item.dtlauthorized ? '#a2fca5' : '#fad26b') , 'border-radius':'4px' }">
            <base-edit-dialog
              :value="item.dtlgeraete === null ? '' : item.dtlgeraete"
              :id="item._id"
              label="Geräte in DTL"
              @input="({ value, id }) => savedtl(id, value)"
            />
          </div>
        </template>
        <template #item.music="{item}">
          <vue-dropzone
            v-if="!item.music"
            ref="attachments"
            id="attachments"
            class="stbw2023teamturnermusicdropzone"
            :options="{ ...dropzoneOptions, accept: (file) => addAttachment(file, item._id) }"
          />
          <div v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <a :href="`${imageBase}${item.music._id}`" :download="item.music.filename" target="_blank"><v-icon>far fa-download</v-icon></a>
                </span>
              </template>
              <span>{{ item.music.filename }}</span>
            </v-tooltip>

            <v-btn fab small text @click="delAttachment(item._id)"><v-icon>far fa-trash-alt</v-icon></v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <p><i>* bitte geben Sie an, wie viele Geräteeinsätze die Turnerinnen in der DTL in der Vorsaison hatten. Hatte eine Turnerin einen Einsatz in der DTL, so muss dieser zuerst vom Ligabeauftragten freigegeben werden, das Feld ist dann orange.</i></p>
    <p><i>** laden Sie hier die Bodenmusik für die jeweilige Turnerin ins System. Erlaubt sind nur mp3-Dateien. Ziehen Sie die Datei per Drag&Drop auf das Feld oder klicken Sie auf das Feld um anschließend die Datei auswählen zu können. Soll eine Datei ausgetaucht werden, so muss die alte zunächst gelöscht werden.</i></p>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'
import { GRAPHQLserver } from '@/env'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

const query = `
  _id
  type
  ... on StbW2023League {
    teams {
      team {
        _id name
      }
      athletes {
        person {
          _id givenName familyName
          identifiers(name: "dtbid") { value }
          turnportal(type: "415167291f9b4931aab76622974ccb96") { validFrom validUntil lastCheck club notes banned fixed }
        }
        dtlgeraete
        dtlauthorized
        music { _id filename mimetype }
      }
    }
  }
`

export default {
  name: 'turner',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  components: {
    VueDropzone: vue2Dropzone
  },

  props: {
    id: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    dropzoneOptions: {
      url: () => {},
      thumbnailWidth: 150,
      addRemoveLinks: true,
      autoProcessQueue: false,
      acceptedFiles: '.mp3'
    }
  }),

  computed: {
    league () {
      return this.EventFind?.find(e => !!e.teams?.find(t => t.team._id === this.team))
    },
    teamname () {
      return this.league?.teams?.find(t => t.team._id === this.team)?.team?.name
    },
    teamathletes () {
      return this.league?.teams?.find(t => t.team._id === this.team)?.athletes?.map(a => ({
        ...a.person,
        dtbid: a.person?.identifiers?.[0]?.value,
        turnportal: a.person?.turnportal?.[0],
        identifiers: undefined,
        dtlgeraete: a.dtlgeraete,
        dtlauthorized: a.dtlauthorized,
        music: a.music
      }))
    },
    imageBase () {
      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      const url = document.URL.match(/[^/]*\.de/)

      const address = base[2].match('stb.de') ? url : base[2]

      return `http${base[1] === 'wss' ? 's' : ''}://${address}/uploads/`
    }
  },

  methods: {
    opendtl (person) {
      this.dtlgeraete.open = true
      this.dtlgeraete.athlete = person
      this.dtlgeraete.geraete = 0
    },
    savedtl (id, value) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!, $dtlgeraete: Int) {
            StbW2023LeagueTeamAthleteDtlgeraeteUpdate(id: $id, team: $team, person: $person, dtlgeraete: $dtlgeraete) { ${query} }
          }`,
        variables: {
          id: this.league._id,
          team: this.team,
          person: id,
          dtlgeraete: parseInt(value)
        }
      })
    },
    async addAttachment (files, person) {
      const toBase64 = file => new Promise((resolve) => {
        var reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.readAsDataURL(file)
      })

      const filename = files.name
      const mimetype = files.type
      const filedata = await toBase64(files)

      this.$refs.attachments.removeFile(files)

      await this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!, $filedata: String!, $filename: String!, $mimetype: String!) {
          StbW2023LeagueTeamAthleteMusicAdd(id: $id, team: $team, person: $person, filedata: $filedata, filename: $filename, mimetype: $mimetype) {
            ${query}
          }
        }`,
        variables: {
          id: this.league._id,
          team: this.team,
          person,
          filedata,
          filename,
          mimetype
        }
      })
    },
    delAttachment (person) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $team: UUID!, $person: UUID!) {
          StbW2023LeagueTeamAthleteMusicDelete(id: $id, team: $team, person: $person) {
            ${query}
          }
        }`,
        variables: {
          id: this.league._id,
          team: this.team,
          person
        }
      })
    }
  },

  apollo: {
    EventFind: {
      query: gql`
        query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
      `,
      variables () {
        return {
          parent: this.id
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
